import React from "react";
import "./NewsScreen.scss";

function News() {
  return (
    <div className="News">
      <h2>News Page</h2>
    </div>
  );
}

export default News;
